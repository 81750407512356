.product-landing-page {

	.language-popover {

		&__wrapper {
			top: -2rem;
		}
	}

	.product-pages-header {
		left: auto;

		&__top {
			width: 20rem;
		}

		&__left {
			margin-top: 1rem;
			padding-bottom: 19.2rem;
			height: 100vh;
		}
	}

	.product-type-list {
		padding-top: 13rem;
		// margin-left: 15rem;

		.product-type {
			min-height: 40rem;
			padding: 5rem 0;
			display: flex;
			align-items: flex-end;

			a {
				width: 70%;
				margin: 0 auto 4rem;
			}
		}


		&--obeauty {

			.product-type {
				padding: 7rem 1.5rem;

				&__image {
					margin-bottom: 3rem;
					max-height: 35rem;
				}
			}

		}

	}
}

.product-pages-header {
	top: 6.6rem;
	z-index: 55;

	&__left {
		margin-top: 1rem;
		height: 100vh;
		overflow: visible;
		padding-bottom: 20rem;
	}
}

.product-pages-header__left {
	a:hover {
		color: $green-1;
	}

	.product-pages-header__left__filter-menu--obeauty {
		.icon--view {
			font-size: 4rem;
		}
	}
}

.product-pages-header__left--no-filter {

	.product-pages-header__left__filter-menu {

		&:hover {
			.filter-menu__text {
				opacity: 1;
				transform: translateX(0);
			}
		}

		li:first-child {
			.filter-menu__text {
				left: 6rem;
				top: 1.7rem;
			}

			a:before {
				top: 1.8rem;
			}
		}

		.filter-menu__text {
			display: block;
			font-size: 1.4rem;
			position: absolute;
			top: 0.7rem;
			left: 7rem;
			width: 25rem;
			height: 4rem;
			line-height: 4rem;
			transform: translateX(-2rem);
			opacity: 0;
			text-align: left;
			transition: transform 0.5s ease, opacity 0.5s ease;
		}

		a {
			position: relative;
			transition: color 0.5 ease;

			&:before {
				content: ' ';
				display: block;
				position: absolute;
				width: 0;
				top: 0.9rem;
				height: 4rem;
				background-color: $green-1;
				transition: width 0.5s ease;
			}
		}

		a:hover {
			color: $white;

			.icon {
				position: relative;
				color: $white;
			}

			&:before {
				width: 31.5rem;
			}
		}

		&--obeauty {
			.filter-menu__text {
				top: -0.5rem;
				height: 6.4rem;
				line-height: 6.4rem;
			}

			a {
				&:before {
					top: -0.4rem;
					height: 6.4rem;
				}
			}

			li:first-child {
				.filter-menu__text {
					left: 6rem;
					top: 0;
				}

				a:before {
					top: 0;
				}
			}
		}
	}
}
