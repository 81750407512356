.product-detail-page {
	margin-top: 13rem;
	// border-top: 1px solid $border-top;

	.product-slider {

		.dot-link {

			a:hover {
				background-color: $black;
			}
		}
	}

	.list-product-detail {
		max-width: 117rem;

		&__items {

			.products-items {
				padding-top: 8rem;
			}
		}

	}

	.detail-product {
		max-width: 117rem;
	}

	.navbar-product {
		max-width: 114rem;

		ul {
			text-align:  right;
			float: right;
			width: 100%;
			margin-bottom: 0;
			// padding-right: 10%;
		}

		li {
			display: inline-block;
			font-size: 14px;

			a {
				color: $white;
			}

			i {
				color: $white;
			}
		}

		.top {
			display: block;
			padding-top: 2rem;

			li {
				// &:hover {
				// 	color: $grey-7;
				// }
				border-left: 1px solid $grey-11;

				&:first-child {
					border-left: 0;
				}

				&:last-child  a {
					padding-right: 0;
				}
			}

			a {
				transition: color 0.3s linear;
				padding: 0 4rem;
				font-weight: 500;
				text-transform: uppercase;

				&:hover {
					color: $grey-7;
					// font-weight: 600;
				}

				i {
					font-size: 1.6rem;
					padding-right: 1rem;
					padding-top: 0.5rem;
				}
			}
		}
	}

	.detail-right {
		.icon-connect {
			a {
				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
}
