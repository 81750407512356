
.navbar-toggle {
	display: none;
}

.mennu-toggle {
	display: none;
}

.header {
	height: 9.3rem;
	margin: 0 auto;
	overflow: visible;

	&--light {

		.header__background {
			height: 9.3rem;
		}
	}

	&--product-pages {
		height: 6.7rem;
		border-bottom: 1px solid $grey-8;

		.header__inner {
			padding: 0.7rem;
			background-color: $white;
			height: 6.6rem;
		}
	}

	&__content {
		max-width: 117rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 2.5rem;
	}

	.logo {
		width: auto;
		overflow: visible;
		position: relative;
	}

	&__trademark-popover {
		position: absolute;
		width: 100%;
		background-color: $white;
		top: 9.3rem;
		display: none;

		&--product {
			display: block;
			top: 6.7rem;

			.header__trademark-popover__wrapper {
				padding-top: 1rem;
				padding-bottom: 1rem;
			}
		}

		&__wrapper {
			max-width: 117rem;
			padding-top: 4rem;
			padding-bottom: 4rem;
		}

		&__header {
			line-height: 2;
			font-weight: 400;
			text-transform: uppercase;
			margin-top: 1.5rem;
		}

		&__list-item-name {
			margin: 0 auto;
			color: $grey-7;
			text-transform: uppercase;
			font-size: 1.4rem;
			padding-top: 2.5rem;
		}

		ul {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 0;
			display: inline-block;
			text-align: center;
			margin-right: 4.5rem;

			&:last-child {
				margin-right: 0;
			}

			img {
				width: 80%;
			}
		}
	}
}

.header__inner {
	height: 9.3rem;
	width: 100%;
	position: absolute;
	top: 0;
	overflow: hidden;
	padding-top: 1.5rem;
	padding-left: 0;

	&--light {
		width: 100%;

		.navbar {

			li {
				a {
					color: $grey-10;

					&.active, &:hover {

						&:before {
							background-color: $green-1;
						}
					}
				}

			}
		}

		.top li {
			border-color: $grey-11;

			a {
				color: $grey-10;

				&.active {
					.icon {
						color: $grey-10;
					}
				}

				&.active:hover {
					.icon {
						color: rgba(155, 155, 155, 0.4);
					}
				}

			}

			.icon {
				transition: color 0.3s linear;
				color: rgba(89, 89, 89, 0.4);
			}
		}
	}
}


.navbar {
	display: inline-block;
	padding-top: 0;
	flex-grow: 1;

	ul {
		text-align:  right;
		float: right;
		width: 100%;
		margin-bottom: 0;
	}

	li {
		display: inline-block;
		font-size: 14px;

		a {
			color: $white;
		}
	}

	.top {
		display: block;

		.social-list {
			padding: 0 0.7rem;
		}

		li {
			padding: 0 1.5rem;

			&:hover {
				color: $grey-7;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		a {
			transition: color 0.3s linear;
			font-weight: 300;
			padding: 0;

			&.active {
				font-weight: 700;
			}

			&.active:hover {
				color: rgba(155, 155, 155, 0.4);
			}

			&:hover {
				color: $grey-7;

				.icon {
					transition: color 0.3s linear;
					color: rgba(155, 155, 155, 0.4);
				}
			}

			.icon {
				padding-right: 0.5rem;
			}

			.icon--recruit {
				font-size: 1.8rem;
				vertical-align: top;
			}

			.icon--corf {
				font-size: 1.8rem;
				vertical-align: top;
			}

			.icon--home {
				font-size: 1.7rem;
				vertical-align: top;
			}

			.icon--store {
				font-size: 1.7rem;
				vertical-align: top;
			}
		}
	}

	.bottom {
		display: block;
		margin-right: -1.5rem;

		li {
			// margin-left: 3rem;
			margin-bottom: 0.5rem;
			text-align: center;
			border-bottom: 0;
		}

		a {
			text-transform: uppercase;
			display: inline-block;
			position: relative;
			transform: translateZ(0);
			transition: all 0.3s ease-in-out;
			padding: 0 1.5rem;
			// font-size: 1.2rem;
			font-weight: 300;

			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0;
				left: 50%;
				transform: translateX(-50%);
				bottom: -4px;
				background-color: $green-2;
				height: 2px;
				transition-property: width;
				transition-duration: 0.3s;
				transition-timing-function: ease-out;
			}
		}

		.active, a:hover {

			&:before {
				// background-color: $grey-7;
				background-color: $green-2;
				width: 2rem;
			}
		}

		.active {
			font-weight: 700;
		}
	}
}

.language-popover {
	position: absolute;
	z-index: 5;
	top: 9.3rem;
	width: 100%;
	display: none;

	&__inner {
		max-width: 117rem;
		margin: 0 auto;
		padding: 0 1.5rem;
		text-align: right;
		position: relative;
	}

	&__wrapper {
		position: absolute;
		display: inline-block;
		right: 0;
		top: -4rem;
		background-color: $white;
		text-align: left;
		padding: 0.6rem 1rem;
		border-radius: 0.5rem;
		border: 1px solid $grey-7;
	}


	&__arrow {
		position: absolute;
		top: -1rem;
		width: 0;
		height: 0;
		right: 3rem;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $grey-7;

		&:after {
			content: '';
			position: absolute;
			bottom: -20px;
			left: -19px;
			width: 0;
			height: 0;
			border-left: 19px solid transparent;
			border-right: 19px solid transparent;
			border-bottom: 19px solid $white;
		}
	}

	&__content {


		.language-popover__language-list {
			margin: 0;
			padding: 0;

			li {
				font-size: 1.4rem;
				display: block;
				height: 2.8rem;
				line-height: 2;
				padding: 0;
				margin: 0;

				&:last-child {
					a {
						color: $grey-5;
					}
				}

				a {
					font-weight: 400;
					color: $grey-10;

					&:hover, &.active {
						color: $black;
					}
				}
			}

		}

		img {
			margin-right: 0.9rem;
			width: 1.5rem;
		}
	}

}
