.home-item {
	&--1 {
		.thumb, .desc {
			height: 42rem;
			float: right;
			padding-top: 8%;
		}
	}
}


.cover-block-video {
	padding-bottom: 0;

	&__checkimg-respon {
		height: auto;
	}
}
