$bg-list: #F7F9F8;
$boder-color: #979797;
.private-label-page {

	.introduce {

		&--full-height {
			// height: 100vh;
			min-height: 60rem;
			.review-360 {
				padding-bottom: 43%;
				height: 100vh;
			}
		}
		&--change {
			max-width: 117rem;

		}

		&__content {
			max-width: 117rem;

			.introduce-text {
				padding: 6% 1.5rem;
				padding-right: 7%;
			}
		}

		.thumb {

			&.bg-ome {
				padding-top: 7%;
				width: 47%;
				padding-bottom: 40%;
				margin-right: 1.5rem;
				float: right;
			}
			&.quality {
				padding-right: 0;
				padding-left: 0;
			}

		}
	}

	.demand {
		margin: 0 auto;
		background-color: $bg-list;

		&__desc {
			padding: 5rem 1.5rem;
			padding-top: 10rem;
			width: 55%;
		}

		.read-more-content {
			margin-bottom: 4rem;
		}
	}

	.list-product {
		overflow: visible;

		&__images {

			// text-align: center;
			padding-right: 10rem;
			padding-left: 6rem;

			img {
				display: none;

			}

			&--change {

				img {
					max-height: 100rem;
					width: auto;
				}
			}
		}
		&__desc {
			padding: 0;
			padding-right: 1.5rem;
		}
	}
	.slide-list {
		width: 100%;
		position: relative;
		display: inline-block;
		h2 {
			width: 70%;
		}
		.btn-product {
			position: absolute;
			right: 0;
			top: 1rem;
			.btn-prev {
				width: 4.8rem;
				height: 4.8rem;
				border: $black 1px solid;
				padding-left: 1.5rem;
				padding-top: 1.5rem;
				display: inline;
				float: left;
				margin-right: 1rem;
			}
			.btn-next {
				width: 4.8rem;
				height: 4.8rem;
				border: $black 1px solid;
				padding-left: 1.5rem;
				padding-top: 1.5rem;
				display: inline;
				float: left;
			}
		}


	}

	.list-product {
		max-width: 117rem;
		padding-top: 0;
		.images-num {
			display: block;
			text-align: right;
			right: 10%;

			.active {
				font-weight: 700;
			}
		}
	}

	.mennu-product {
		display: inline-block;
		height: 8rem;
		background-color: $white;
		overflow: hidden;
		width: 100%;
		margin: 0 auto;
		text-align: right;
		position: relative;
		.mennu-items {
			position: absolute;
			max-width: 117rem;
			top:30%;
		}
		a {
			padding-right: 2rem;
			.acti {
				font-weight: 500;
			}
		}
	}

	.private-product-list {


		&__container {
			margin-bottom: 0;
			height: 55rem;
		}

		&__header {
			text-align: left;
			padding-top: 8rem;
			display: block;
			background-color: $white;
			padding-left: 0;

			a {
				display: inline-block;
				margin-right: 3rem;
				transform: translateZ(0);
    			transition: all 0.3s ease-in-out;
    			line-height: 2;
    			font-size: 1.4rem;

				&:hover {
					color: $green-1;
					// border-bottom: 2px solid $green-1;
				}
			}

			.active {
				color: $green-1;
				border-bottom: 2px solid $green-1;
			}

			.mennu-border {
				    display: inline-flex;
				    margin-bottom: 1.5rem;

				li {
					// border-bottom: 1px solid $boder-color;
					// opacity: 0.1;
					border-bottom : solid 1px rgba(151,151,151,0.1);

				}
				li:last-child {
					// padding-right: 1.5rem;
					a {
						margin-right: 0;
					}
				}
			}
		}
	}


	.list-product__desc {

		.images-list {
			margin: 0;
			width: 100%;


			.full-width {
				width: 33.33%;
				.set-width {
					width: 100%;
					height: 22rem;
					margin-bottom: 3rem;
				}
				img {
					 width: 100%;
					 height: 100%;
				}
			}
			.full-height {
				width: 33.33%;

				.set-hieght {
					height: 20rem;
					width: 100%;
					margin-bottom: 3rem;
				}
				img {
					height: 100%;
				}
			}
		}
	}
	.desc-title {
		width: 80%;
		overflow: hidden;
		margin-bottom: 2.5rem;
	}


	.display-mb {
		display: none;
	}
	.display-tl {
		display: none;
	}
	.display-dk {
		display: block;
	}
	.display-tabl-desk {
		display: block;
	}

	.private-product-inner-slider {
		position: relative;
		float: left;

		.bx-controls {
			position: absolute;
			top: 50%;
			transform: translateY(-130%);
			width: 100%;
			pointer-events: none;

			a {
				pointer-events: visible;
			}
		}

		.bx-controls-direction {
			display: flex;
			justify-content: space-between;
		}

		.bx-prev, .bx-next {
			font-size: 1.8rem;
			background-color: $black;
			padding: 0.1rem 0.8rem;
			color: $white;
			border: 1px solid $white;

			.icon {
				font-size: 13px;
			}
		}

		.bx-prev {
			margin-left: -1.4rem;
		}

		.bx-next {
			margin-right: -1.4rem;
		}
	}

}
