/**
 * Styles for desktop and larger-than-desktop
 */
@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .set-sub-margin-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .section-cover {
    text-align: center;
    height: 100vh;
    min-height: 60rem;
  }
  .section-cover__content {
    position: absolute;
    width: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
  .section-cover__content__title {
    margin-bottom: 4rem;
  }
  .sidebar-right {
    float: right;
  }
  .bg-cover-other-pr {
    padding-top: 0;
    position: relative;
    padding-bottom: 0;
    height: 100%;
  }
  .bg-cover-other-pr--banner-top-static {
    padding-bottom: 0%;
  }
  .content-container {
    max-width: 117rem;
    padding: 0 10rem;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
  .content-container:after {
    content: '';
    display: table;
    clear: both;
  }
  .content-width {
    max-width: 117rem;
    padding: 0 15px;
  }
  /* ========Setup Animate Scroll ===========*/
  .js-title-animate-hide {
    transform: translateY(100%) !important;
    opacity: 0 !important;
    top: 100%;
  }
  .js-content-animate-hide {
    transform: translateY(100%) !important;
    opacity: 0 !important;
    top: 100%;
  }
  .js-title-animate-show {
    top: 50%;
    transform: translateY(-70%) !important;
    opacity: 1 !important;
    transition: all ease 0.3s;
  }
  .js-content-animate-show {
    top: 10%;
    transform: translateY(-20%) !important;
    opacity: 1 !important;
    transition: all ease 2s;
  }
  .js-position-hide-left {
    transform: translateX(-100%);
    opacity: 0;
  }
  .js-position-hide-right {
    transform: translateX(100%);
    opacity: 0;
  }
  .js-position-hide-fade {
    opacity: 0;
  }
  .js-position-hide-top {
    transform: translateY(-100%);
    opacity: 0;
  }
  .js-position-show-left {
    transform: translateX(0%);
    opacity: 1;
    transition: all ease 2s !important;
  }
  .js-position-show-right {
    transform: translateX(0%);
    opacity: 1;
    transition: all ease 2s !important;
  }
  .js-position-show-fade {
    opacity: 1;
    transition: all ease 0.5s;
  }
  .js-position-show-top {
    transform: translateY(0%);
    opacity: 1;
    transition: all ease 0.1s;
  }
  .js-position-hide-below {
    transform: translateY(50%);
    opacity: 0;
  }
  .js-position-show-below {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 3s cubic-bezier(0.19, 1, 0.22, 1) !important;
  }
  .js-position-title-hide-below, .js-position-content-animate-hide {
    transform: translateY(50%);
    opacity: 0;
  }
  .js-position-title-show-below {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1) !important;
  }
  .js-position-content-animate-show {
    transform: translateY(0%);
    opacity: 1;
    transition: all 3s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .js-cover-set-dib {
    display: inline-block;
    margin-bottom: -0.7rem;
  }
  .custom-position-animate .js-title-animate-show {
    transform: translateY(-30%) !important;
  }
  .js-change-logo {
    transition: all ease 0.4s;
  }
  .js-animate-banner-content-stat-1 {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10%);
    transition: all ease 0.3s;
  }
  .js-animate-banner-content-stat-2 {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%);
    transition: all ease 0.3s;
  }
  /* ======== end Setup Animate Scroll ===== */
  .navbar-toggle {
    display: none;
  }
  .mennu-toggle {
    display: none;
  }
  .header {
    height: 9.3rem;
    margin: 0 auto;
    overflow: visible;
  }
  .header--light .header__background {
    height: 9.3rem;
  }
  .header--product-pages {
    height: 6.7rem;
    border-bottom: 1px solid #f6f6f6;
  }
  .header--product-pages .header__inner {
    padding: 0.7rem;
    background-color: #fff;
    height: 6.6rem;
  }
  .header__content {
    max-width: 117rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2.5rem;
  }
  .header .logo {
    width: auto;
    overflow: visible;
    position: relative;
  }
  .header__trademark-popover {
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 9.3rem;
    display: none;
  }
  .header__trademark-popover--product {
    display: block;
    top: 6.7rem;
  }
  .header__trademark-popover--product .header__trademark-popover__wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .header__trademark-popover__wrapper {
    max-width: 117rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .header__trademark-popover__header {
    line-height: 2;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 1.5rem;
  }
  .header__trademark-popover__list-item-name {
    margin: 0 auto;
    color: #9b9b9b;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding-top: 2.5rem;
  }
  .header__trademark-popover ul {
    margin-bottom: 0;
  }
  .header__trademark-popover li {
    margin-bottom: 0;
    display: inline-block;
    text-align: center;
    margin-right: 4.5rem;
  }
  .header__trademark-popover li:last-child {
    margin-right: 0;
  }
  .header__trademark-popover li img {
    width: 80%;
  }
  .header__inner {
    height: 9.3rem;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding-top: 1.5rem;
    padding-left: 0;
  }
  .header__inner--light {
    width: 100%;
  }
  .header__inner--light .navbar li a {
    color: #595959;
  }
  .header__inner--light .navbar li a.active:before, .header__inner--light .navbar li a:hover:before {
    background-color: #3ab24a;
  }
  .header__inner--light .top li {
    border-color: #c3c3c4;
  }
  .header__inner--light .top li a {
    color: #595959;
  }
  .header__inner--light .top li a.active .icon {
    color: #595959;
  }
  .header__inner--light .top li a.active:hover .icon {
    color: rgba(155, 155, 155, 0.4);
  }
  .header__inner--light .top li .icon {
    transition: color 0.3s linear;
    color: rgba(89, 89, 89, 0.4);
  }
  .navbar {
    display: inline-block;
    padding-top: 0;
    flex-grow: 1;
  }
  .navbar ul {
    text-align: right;
    float: right;
    width: 100%;
    margin-bottom: 0;
  }
  .navbar li {
    display: inline-block;
    font-size: 14px;
  }
  .navbar li a {
    color: #fff;
  }
  .navbar .top {
    display: block;
  }
  .navbar .top .social-list {
    padding: 0 0.7rem;
  }
  .navbar .top li {
    padding: 0 1.5rem;
  }
  .navbar .top li:hover {
    color: #9b9b9b;
  }
  .navbar .top li:last-child {
    padding-right: 0;
  }
  .navbar .top a {
    transition: color 0.3s linear;
    font-weight: 300;
    padding: 0;
  }
  .navbar .top a.active {
    font-weight: 700;
  }
  .navbar .top a.active:hover {
    color: rgba(155, 155, 155, 0.4);
  }
  .navbar .top a:hover {
    color: #9b9b9b;
  }
  .navbar .top a:hover .icon {
    transition: color 0.3s linear;
    color: rgba(155, 155, 155, 0.4);
  }
  .navbar .top a .icon {
    padding-right: 0.5rem;
  }
  .navbar .top a .icon--recruit {
    font-size: 1.8rem;
    vertical-align: top;
  }
  .navbar .top a .icon--corf {
    font-size: 1.8rem;
    vertical-align: top;
  }
  .navbar .top a .icon--home {
    font-size: 1.7rem;
    vertical-align: top;
  }
  .navbar .top a .icon--store {
    font-size: 1.7rem;
    vertical-align: top;
  }
  .navbar .bottom {
    display: block;
    margin-right: -1.5rem;
  }
  .navbar .bottom li {
    margin-bottom: 0.5rem;
    text-align: center;
    border-bottom: 0;
  }
  .navbar .bottom a {
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    transform: translateZ(0);
    transition: all 0.3s ease-in-out;
    padding: 0 1.5rem;
    font-weight: 300;
  }
  .navbar .bottom a:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    background-color: #3ab24a;
    height: 2px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .navbar .bottom .active:before, .navbar .bottom a:hover:before {
    background-color: #3ab24a;
    width: 2rem;
  }
  .navbar .bottom .active {
    font-weight: 700;
  }
  .language-popover {
    position: absolute;
    z-index: 5;
    top: 9.3rem;
    width: 100%;
    display: none;
  }
  .language-popover__inner {
    max-width: 117rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    text-align: right;
    position: relative;
  }
  .language-popover__wrapper {
    position: absolute;
    display: inline-block;
    right: 0;
    top: -4rem;
    background-color: #fff;
    text-align: left;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #9b9b9b;
  }
  .language-popover__arrow {
    position: absolute;
    top: -1rem;
    width: 0;
    height: 0;
    right: 3rem;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #9b9b9b;
  }
  .language-popover__arrow:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: -19px;
    width: 0;
    height: 0;
    border-left: 19px solid transparent;
    border-right: 19px solid transparent;
    border-bottom: 19px solid #fff;
  }
  .language-popover__content .language-popover__language-list {
    margin: 0;
    padding: 0;
  }
  .language-popover__content .language-popover__language-list li {
    font-size: 1.4rem;
    display: block;
    height: 2.8rem;
    line-height: 2;
    padding: 0;
    margin: 0;
  }
  .language-popover__content .language-popover__language-list li:last-child a {
    color: #979797;
  }
  .language-popover__content .language-popover__language-list li a {
    font-weight: 400;
    color: #595959;
  }
  .language-popover__content .language-popover__language-list li a:hover, .language-popover__content .language-popover__language-list li a.active {
    color: #000;
  }
  .language-popover__content img {
    margin-right: 0.9rem;
    width: 1.5rem;
  }
  .home-item--1 {
    display: block;
    position: relative;
    height: 100vh;
    min-height: 60rem;
    padding: 0;
  }
  .home-item--1 .thumb, .home-item--1 .desc {
    vertical-align: middle;
    padding-top: 0%;
  }
  .home-item--1 .sub-heading {
    font-size: 4.4rem;
  }
  .home-item--1__content-container {
    height: 100vh;
    min-height: 60rem;
    position: relative;
    max-width: 117rem;
    margin: 0 auto;
  }
  .home-item--1__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
    right: 1.5rem;
    opacity: 1;
    transition: all ease 0.4s;
    animation-direction: alternate;
  }
  .home-item--1__content.playing {
    transform: translate(150%, -50%);
    opacity: 0;
    transition: all ease .4s;
  }
  .home-item--2 {
    height: 100vh;
    min-height: 60rem;
  }
  .home-item--2__content {
    line-height: 2;
    color: #fff;
  }
  .cover-block-video {
    background-size: cover;
    background-position: right;
    position: absolute;
    width: 45%;
    height: 100vh;
    min-height: 60rem;
    top: 0;
    left: 0;
    transition: all .5s ease;
    animation-direction: alternate;
  }
  .cover-block-video__checkimg-respon {
    height: auto;
  }
  .cover-block-video__overlay {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
  }
  .cover-block-video__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cover-block-video.playing {
    width: 100%;
    transition: all ease .4s;
  }
  .behind-cover-block-video {
    position: absolute;
    width: 45%;
    height: 100vh;
    min-height: 60rem;
    margin-top: 0;
    background-size: cover;
    display: none;
    background-position: right;
    top: 0;
    left: 0;
  }
  .behind-cover-block-video.playing {
    width: 100%;
    transition: all ease .4s;
    display: block;
  }
  .bg-multi-kem {
    position: relative;
    width: 100%;
  }
  .bg-multi-kem .home-item--2__content {
    position: absolute;
    top: 50%;
    left: inherit;
    transform: translateY(-50%);
    width: 47%;
  }
  .bg-multi-kem .home-item--2__content p {
    font-weight: 300;
  }
  .bg-multi-kem .home-item--2__content p.bold-text {
    font-weight: 600;
    text-align: left;
  }
  .distribute__margin {
    margin-left: auto;
    margin-right: auto;
  }
  .list-logo-branch {
    margin: 6rem 0 10rem;
  }
  .list-logo-branch ul {
    display: flex;
    justify-content: space-between;
    padding: 0 5rem;
    align-items: flex-end;
  }
  .list-logo-branch ul li {
    width: auto;
    margin: 0;
    height: auto;
    position: relative;
  }
  .list-logo-branch ul li a {
    text-align: center;
  }
  .list-logo-branch ul li.list-logo-branch__separator {
    height: 6rem;
    width: 1px;
    background-color: #ddd;
  }
  .footer__left {
    text-align: left;
  }
  .footer__right {
    text-align: right;
  }
  .tabs__content {
    height: 100vh;
    min-height: 60rem;
  }
  .homepage .history-us {
    position: relative;
    height: 100%;
  }
  .homepage .history-us__period {
    position: absolute;
    left: 1.5rem;
    margin-top: 7%;
    top: 0;
    padding-top: 15rem;
  }
  .homepage .history-us__slide-index-text {
    display: none;
  }
  .homepage .history-us__slide-index {
    position: relative;
    display: inline-block;
    width: 2rem;
    margin-right: .8rem;
  }
  .homepage .history-us__slide-index::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: ' ';
    display: block;
    width: 1rem;
    height: .3rem;
    background-color: #ddd;
    transition: all .3s ease-out;
  }
  .homepage .history-us li {
    margin-bottom: 0;
    height: 100%;
  }
  .homepage .history-us .active::before {
    width: 2rem;
    margin-left: -.5rem;
    background-color: #000;
  }
  .homepage .history-us .active ~ .history-us__slide-index-text {
    display: inline-block;
    color: #2a3c3e;
    vertical-align: top;
    padding-left: 1rem;
    line-height: 0.3;
    height: 1.7rem;
    border-bottom: 1px solid #ddd;
  }
  .homepage .history-us__header {
    display: flex;
    justify-content: space-between;
  }
  .homepage .history-us__header .sub-heading {
    display: inline-block;
  }
  .homepage .history-us__header2 {
    padding: 7rem 10rem 0;
    display: flex;
    position: relative;
  }
  .homepage .history-us__header2 .sub-heading {
    display: inline-block;
  }
  .homepage .history-us__header2 .history-us__btn-group {
    position: absolute;
    right: 1.5rem;
  }
  .homepage .history-us__btn-group {
    display: inline-block;
  }
  .homepage .history-us__btn {
    display: inline-block;
    margin-left: 2rem;
    height: 5rem;
  }
  .homepage .history-us__btn .bx-prev {
    position: relative;
    top: 1.4rem;
    padding: 1.2rem 1.5rem 1rem;
    border: 2px solid #000;
  }
  .homepage .history-us__btn .bx-next {
    position: relative;
    top: 1.4rem;
    padding: 1.2rem 1.5rem 1rem;
    border: 2px solid #000;
  }
  .homepage .history-us__content {
    transition: all .3s ease-out;
    margin: 0 auto;
    padding-top: 4%;
    max-width: 117rem;
    padding-left: 10rem;
    padding-right: 1.5rem;
    min-height: 45rem;
  }
  .homepage .history-us__text {
    display: inline-block;
    width: 50%;
    padding-right: 6rem;
    padding-left: 0;
  }
  .homepage .history-us__image {
    margin-top: 4rem;
    width: 50%;
    padding-left: 6rem;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1330px) {
  .homepage .history-us__content {
    padding-left: 22rem;
  }
  .homepage .history-us__text {
    padding-right: 4rem;
  }
  .homepage .history-us__image {
    padding-left: 4rem;
  }
}

@media (min-width: 992px) {
  .contact-page {
    margin-top: 8rem;
  }
  .contact-page .main {
    padding-top: 15rem;
  }
  .contact-page .title {
    font-weight: 300;
    font-size: 3.6rem;
    width: 50%;
    margin: 0 auto 8rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .contact-page .contact-form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0;
  }
  .contact-page .contact-form textarea::placeholder, .contact-page .contact-form input::placeholder {
    color: #000;
  }
  .contact-page .contact-form textarea {
    height: 14.5rem;
  }
  .contact-page .block-list-store {
    margin-top: 1rem;
  }
  .contact-page .connect {
    border-top: 1px solid #ddd;
    background-color: #fff;
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .contact-page .connect .main-title--1 {
    text-transform: none;
    font-size: 22px;
  }
  .contact-page .connect p {
    line-height: 2;
    font-size: 1.6rem;
  }
  .contact-page .connect__address {
    margin-top: 0;
  }
  .contact-page .connect__address .main-title--1 {
    margin-bottom: 1.2rem;
  }
  .contact-page .connect__address p {
    color: #000;
  }
  .contact-page .connect__address span {
    width: 7rem;
  }
  .contact-page .connect .follow__social a {
    margin-left: 3rem;
  }
  .contact-page .connect .follow__social a:first-child {
    margin-left: 0;
  }
  .form-contact-area {
    margin-bottom: 0;
  }
  .third-party-logos {
    text-align: left;
  }
  .bg-baner {
    opacity: 0;
    transition: opacity 1.5s ease;
  }
  .bg-baner .banner-item .content-container {
    position: relative;
    height: 100vh;
  }
  .bg-baner .banner-item__content {
    position: absolute;
    width: 65rem;
    left: inherit;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .bg-baner .banner-item__content .main-heading {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    top: 10rem;
    opacity: 0;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .bg-baner .banner-item__content .content-arrow {
    position: relative;
    display: block;
    padding: 0;
    top: 10rem;
    opacity: 0;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .bg-baner .banner-item__content .content-arrow p {
    width: 85%;
  }
  .main-banner-height-45 {
    position: relative;
    height: 45rem;
  }
  .main-banner-height-45:after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  .arrow-des {
    margin: 0;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .arrow-des .bx-prev, .arrow-des .bx-next {
    padding: 1.5rem;
    display: inline-block;
    color: #fff;
  }
  .arrow-prev {
    float: left;
  }
  .arrow-next {
    float: right;
  }
  .arrow-tab {
    display: block;
    margin: auto;
  }
  .trademark-omi-page .introduce, .trademark-obeauty-page .introduce, .trademark-nghia-page .introduce, .trademark-export-page .introduce {
    padding: 6rem 1.5rem 14rem;
    margin: 0 auto;
  }
  .trademark-omi-page .introduce .thumb img, .trademark-obeauty-page .introduce .thumb img, .trademark-nghia-page .introduce .thumb img, .trademark-export-page .introduce .thumb img {
    width: 80%;
    height: auto;
    text-align: right;
  }
  .trademark-omi-page .introduce .introduce-text, .trademark-obeauty-page .introduce .introduce-text, .trademark-nghia-page .introduce .introduce-text, .trademark-export-page .introduce .introduce-text {
    padding-right: 0;
    padding-top: 3rem;
  }
  .trademark-omi-page .products, .trademark-obeauty-page .products, .trademark-nghia-page .products, .trademark-export-page .products {
    padding-left: 0;
    padding-right: 0;
  }
  .trademark-omi-page .tabs-item--1 .desc, .trademark-obeauty-page .tabs-item--1 .desc, .trademark-nghia-page .tabs-item--1 .desc, .trademark-export-page .tabs-item--1 .desc {
    margin: 0 auto;
    padding: 2rem 0 0;
  }
  .trademark-omi-page .tabs__content, .trademark-obeauty-page .tabs__content, .trademark-nghia-page .tabs__content, .trademark-export-page .tabs__content {
    padding-left: 0;
    padding-right: 0;
    min-height: auto;
    height: auto;
  }
  .trademark-omi-page .tabs--trademark .export-desc, .trademark-obeauty-page .tabs--trademark .export-desc, .trademark-nghia-page .tabs--trademark .export-desc, .trademark-export-page .tabs--trademark .export-desc {
    overflow: hidden;
    display: table;
    padding-left: 0;
    padding-right: 0;
  }
  .trademark-omi-page .tabs--trademark .export-desc p, .trademark-obeauty-page .tabs--trademark .export-desc p, .trademark-nghia-page .tabs--trademark .export-desc p, .trademark-export-page .tabs--trademark .export-desc p {
    text-align: left;
    padding-left: 0;
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
  .trademark-omi-page .tabs--trademark .export-desc .thumb, .trademark-obeauty-page .tabs--trademark .export-desc .thumb, .trademark-nghia-page .tabs--trademark .export-desc .thumb, .trademark-export-page .tabs--trademark .export-desc .thumb {
    padding-bottom: 0;
    display: table-cell;
    vertical-align: middle;
  }
  .trademark-omi-page .export-item--1, .trademark-obeauty-page .export-item--1, .trademark-nghia-page .export-item--1, .trademark-export-page .export-item--1 {
    display: table;
  }
  .trademark-omi-page .export-item--1 .desc, .trademark-obeauty-page .export-item--1 .desc, .trademark-nghia-page .export-item--1 .desc, .trademark-export-page .export-item--1 .desc {
    padding: 10rem 8.5rem;
  }
  .trademark-omi-page .export-item .desc, .trademark-omi-page .export-item .thumb, .trademark-obeauty-page .export-item .desc, .trademark-obeauty-page .export-item .thumb, .trademark-nghia-page .export-item .desc, .trademark-nghia-page .export-item .thumb, .trademark-export-page .export-item .desc, .trademark-export-page .export-item .thumb {
    display: table-cell;
    float: none;
    vertical-align: top;
  }
  .trademark-omi-page .export-item .thumb.sharpen-imports, .trademark-obeauty-page .export-item .thumb.sharpen-imports, .trademark-nghia-page .export-item .thumb.sharpen-imports, .trademark-export-page .export-item .thumb.sharpen-imports {
    background-size: cover;
    background-position: center center;
    width: 50%;
    padding-bottom: 37%;
  }
  .trademark-omi-page .carousel--banner, .trademark-obeauty-page .carousel--banner, .trademark-nghia-page .carousel--banner, .trademark-export-page .carousel--banner {
    height: 100%;
  }
  .trademark-omi-page .sm, .trademark-obeauty-page .sm, .trademark-nghia-page .sm, .trademark-export-page .sm {
    display: inline-block;
  }
  .trademark-omi-page .tl, .trademark-obeauty-page .tl, .trademark-nghia-page .tl, .trademark-export-page .tl {
    display: none;
  }
  .trademark-omi-page .dk, .trademark-obeauty-page .dk, .trademark-nghia-page .dk, .trademark-export-page .dk {
    display: inline-block;
  }
  .about-page .carousel--banner {
    height: 100%;
    /* full view port */
  }
  .about-page .main-banner {
    height: 100vh;
    position: relative;
  }
  .about-page .main-banner__container {
    max-width: 117rem;
    margin: 0 auto;
  }
  .about-page .main-banner__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 300;
    color: #fff;
    width: 55%;
    padding-left: 10rem;
  }
  .about-page .main-banner__content__header {
    font-size: 4.4rem;
  }
  .about-page .content-width {
    padding: 0 15px;
  }
  .about-page .about-item--1 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .about-page .about-item--1 .sharpen-imports {
    right: 0;
    top: 10rem;
    max-width: 117rem;
  }
  .about-page .about-item--1 .desc {
    padding-right: 10rem;
  }
  .about-page .about-item--2 {
    position: relative;
    width: 100%;
    padding-top: 2rem;
    overflow: hidden;
  }
  .about-page .about-item--2 .sharpen-imports {
    left: 0;
    top: 10rem;
  }
  .about-page .about-item--2 .desc {
    float: right;
    padding-left: 2rem;
  }
  .about-page .about-item .desc {
    color: #595959;
    padding-bottom: 0;
    padding-top: 11.4rem;
  }
  .about-page .about-item .thumb.sharpen-imports {
    position: absolute;
    width: 45%;
    padding-bottom: 45%;
    margin-left: 0;
    margin-right: 0;
  }
  .about-page .about-item .thumb.image-desc {
    padding: 10% 0;
    height: auto;
    width: 50%;
  }
  .about-page .about-item .thumb.image-desc img {
    width: 100%;
    height: auto;
  }
  .about-page .video-information {
    margin: 0 auto;
  }
  .about-page .video-information__video-left {
    float: left;
    margin-right: 2rem;
    width: 23%;
  }
  .about-page .video-information__video-left img {
    width: 100%;
  }
  .about-page .video-information__video-main {
    width: 50%;
    float: left;
    margin-left: 0.7rem;
  }
  .about-page .video-information__video-main img {
    width: 100%;
    height: auto;
  }
  .about-page .video-information__video-right {
    float: right;
    width: 23%;
  }
  .about-page .video-information__video-right img {
    width: 100%;
  }
  .about-page .content-reward {
    padding: 0;
  }
  .about-page .content-reward .certify {
    padding-left: 5rem;
  }
  .about-page .content-reward .reward-detail p {
    color: #595959;
    line-height: 2;
  }
  .about-page .content-reward .reward-list {
    margin-top: 8rem;
    margin-bottom: 8rem;
    max-width: 117rem;
  }
  .about-page .content-reward .reward-list .reward {
    display: block;
  }
  .about-page .content-reward .reward-list .reward__item {
    display: inline-block;
    width: 20%;
  }
  .about-page .content-reward .reward-list .reward__item img {
    width: 95%;
  }
  .about-page .content-reward .reward-list .reward .reward-img {
    padding-bottom: 22%;
    width: 15%;
  }
  .about-page .content-reward .reward-list .img-black {
    padding: 0;
    margin: 0 -0.5rem;
  }
  .about-page .content-reward .reward-list .img-black .reward__item {
    width: 20%;
    padding: 0 0.5rem;
    box-sizing: border-box;
  }
  .about-page .content-reward .reward-list .img-black .reward__item img {
    width: 100%;
    height: 100%;
  }
  .about-page .tabs--trademark .export-desc {
    overflow: hidden;
    margin: 0 auto;
    padding: 5% 10%;
  }
  .about-page .tabs--trademark .export-desc img {
    width: 50%;
    height: 50%;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-right: 10%;
  }
  .about-page .tabs--trademark .export-desc p {
    text-align: left;
    padding-left: 0;
    padding-bottom: 0;
  }
  .about-page .tabs--trademark .products {
    overflow: hidden;
    padding: 0 10%;
  }
  .about-page .p-product {
    padding-bottom: 0;
  }
  .about-page .tabs__content {
    padding-left: 0;
    padding-right: 0;
  }
  .about-page .tabs-item--1 .desc {
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  .about-page .reward-dk {
    padding: 5% 10%;
  }
  .about-page .reward-dk img {
    margin-right: 3%;
    margin-bottom: 3%;
    width: 100%;
    max-width: 15rem;
    height: auto;
  }
  .about-page .sm {
    display: none;
  }
  .about-page .tl {
    display: none;
  }
  .about-page .dk {
    display: inline-block;
  }
  .map-wrapper {
    display: block;
  }
  .block-list-store .store-address.active .title-info:after {
    content: '\ea07';
  }
  .connect__form .button--black {
    float: left;
  }
  .private-label-page .introduce--full-height {
    min-height: 60rem;
  }
  .private-label-page .introduce--full-height .review-360 {
    padding-bottom: 43%;
    height: 100vh;
  }
  .private-label-page .introduce--change {
    max-width: 117rem;
  }
  .private-label-page .introduce__content {
    max-width: 117rem;
  }
  .private-label-page .introduce__content .introduce-text {
    padding: 6% 1.5rem;
    padding-right: 7%;
  }
  .private-label-page .introduce .thumb.bg-ome {
    padding-top: 7%;
    width: 47%;
    padding-bottom: 40%;
    margin-right: 1.5rem;
    float: right;
  }
  .private-label-page .introduce .thumb.quality {
    padding-right: 0;
    padding-left: 0;
  }
  .private-label-page .demand {
    margin: 0 auto;
    background-color: #F7F9F8;
  }
  .private-label-page .demand__desc {
    padding: 5rem 1.5rem;
    padding-top: 10rem;
    width: 55%;
  }
  .private-label-page .demand .read-more-content {
    margin-bottom: 4rem;
  }
  .private-label-page .list-product {
    overflow: visible;
  }
  .private-label-page .list-product__images {
    padding-right: 10rem;
    padding-left: 6rem;
  }
  .private-label-page .list-product__images img {
    display: none;
  }
  .private-label-page .list-product__images--change img {
    max-height: 100rem;
    width: auto;
  }
  .private-label-page .list-product__desc {
    padding: 0;
    padding-right: 1.5rem;
  }
  .private-label-page .slide-list {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .private-label-page .slide-list h2 {
    width: 70%;
  }
  .private-label-page .slide-list .btn-product {
    position: absolute;
    right: 0;
    top: 1rem;
  }
  .private-label-page .slide-list .btn-product .btn-prev {
    width: 4.8rem;
    height: 4.8rem;
    border: #000 1px solid;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    display: inline;
    float: left;
    margin-right: 1rem;
  }
  .private-label-page .slide-list .btn-product .btn-next {
    width: 4.8rem;
    height: 4.8rem;
    border: #000 1px solid;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    display: inline;
    float: left;
  }
  .private-label-page .list-product {
    max-width: 117rem;
    padding-top: 0;
  }
  .private-label-page .list-product .images-num {
    display: block;
    text-align: right;
    right: 10%;
  }
  .private-label-page .list-product .images-num .active {
    font-weight: 700;
  }
  .private-label-page .mennu-product {
    display: inline-block;
    height: 8rem;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    text-align: right;
    position: relative;
  }
  .private-label-page .mennu-product .mennu-items {
    position: absolute;
    max-width: 117rem;
    top: 30%;
  }
  .private-label-page .mennu-product a {
    padding-right: 2rem;
  }
  .private-label-page .mennu-product a .acti {
    font-weight: 500;
  }
  .private-label-page .private-product-list__container {
    margin-bottom: 0;
    height: 55rem;
  }
  .private-label-page .private-product-list__header {
    text-align: left;
    padding-top: 8rem;
    display: block;
    background-color: #fff;
    padding-left: 0;
  }
  .private-label-page .private-product-list__header a {
    display: inline-block;
    margin-right: 3rem;
    transform: translateZ(0);
    transition: all 0.3s ease-in-out;
    line-height: 2;
    font-size: 1.4rem;
  }
  .private-label-page .private-product-list__header a:hover {
    color: #3ab24a;
  }
  .private-label-page .private-product-list__header .active {
    color: #3ab24a;
    border-bottom: 2px solid #3ab24a;
  }
  .private-label-page .private-product-list__header .mennu-border {
    display: inline-flex;
    margin-bottom: 1.5rem;
  }
  .private-label-page .private-product-list__header .mennu-border li {
    border-bottom: solid 1px rgba(151, 151, 151, 0.1);
  }
  .private-label-page .private-product-list__header .mennu-border li:last-child a {
    margin-right: 0;
  }
  .private-label-page .list-product__desc .images-list {
    margin: 0;
    width: 100%;
  }
  .private-label-page .list-product__desc .images-list .full-width {
    width: 33.33%;
  }
  .private-label-page .list-product__desc .images-list .full-width .set-width {
    width: 100%;
    height: 22rem;
    margin-bottom: 3rem;
  }
  .private-label-page .list-product__desc .images-list .full-width img {
    width: 100%;
    height: 100%;
  }
  .private-label-page .list-product__desc .images-list .full-height {
    width: 33.33%;
  }
  .private-label-page .list-product__desc .images-list .full-height .set-hieght {
    height: 20rem;
    width: 100%;
    margin-bottom: 3rem;
  }
  .private-label-page .list-product__desc .images-list .full-height img {
    height: 100%;
  }
  .private-label-page .desc-title {
    width: 80%;
    overflow: hidden;
    margin-bottom: 2.5rem;
  }
  .private-label-page .display-mb {
    display: none;
  }
  .private-label-page .display-tl {
    display: none;
  }
  .private-label-page .display-dk {
    display: block;
  }
  .private-label-page .display-tabl-desk {
    display: block;
  }
  .private-label-page .private-product-inner-slider {
    position: relative;
    float: left;
  }
  .private-label-page .private-product-inner-slider .bx-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-130%);
    width: 100%;
    pointer-events: none;
  }
  .private-label-page .private-product-inner-slider .bx-controls a {
    pointer-events: visible;
  }
  .private-label-page .private-product-inner-slider .bx-controls-direction {
    display: flex;
    justify-content: space-between;
  }
  .private-label-page .private-product-inner-slider .bx-prev, .private-label-page .private-product-inner-slider .bx-next {
    font-size: 1.8rem;
    background-color: #000;
    padding: 0.1rem 0.8rem;
    color: #fff;
    border: 1px solid #fff;
  }
  .private-label-page .private-product-inner-slider .bx-prev .icon, .private-label-page .private-product-inner-slider .bx-next .icon {
    font-size: 13px;
  }
  .private-label-page .private-product-inner-slider .bx-prev {
    margin-left: -1.4rem;
  }
  .private-label-page .private-product-inner-slider .bx-next {
    margin-right: -1.4rem;
  }
  .product-landing-page .language-popover__wrapper {
    top: -2rem;
  }
  .product-landing-page .product-pages-header {
    left: auto;
  }
  .product-landing-page .product-pages-header__top {
    width: 20rem;
  }
  .product-landing-page .product-pages-header__left {
    margin-top: 1rem;
    padding-bottom: 19.2rem;
    height: 100vh;
  }
  .product-landing-page .product-type-list {
    padding-top: 13rem;
  }
  .product-landing-page .product-type-list .product-type {
    min-height: 40rem;
    padding: 5rem 0;
    display: flex;
    align-items: flex-end;
  }
  .product-landing-page .product-type-list .product-type a {
    width: 70%;
    margin: 0 auto 4rem;
  }
  .product-landing-page .product-type-list--obeauty .product-type {
    padding: 7rem 1.5rem;
  }
  .product-landing-page .product-type-list--obeauty .product-type__image {
    margin-bottom: 3rem;
    max-height: 35rem;
  }
  .product-pages-header {
    top: 6.6rem;
    z-index: 55;
  }
  .product-pages-header__left {
    margin-top: 1rem;
    height: 100vh;
    overflow: visible;
    padding-bottom: 20rem;
  }
  .product-pages-header__left a:hover {
    color: #3ab24a;
  }
  .product-pages-header__left .product-pages-header__left__filter-menu--obeauty .icon--view {
    font-size: 4rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu:hover .filter-menu__text {
    opacity: 1;
    transform: translateX(0);
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu li:first-child .filter-menu__text {
    left: 6rem;
    top: 1.7rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu li:first-child a:before {
    top: 1.8rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu .filter-menu__text {
    display: block;
    font-size: 1.4rem;
    position: absolute;
    top: 0.7rem;
    left: 7rem;
    width: 25rem;
    height: 4rem;
    line-height: 4rem;
    transform: translateX(-2rem);
    opacity: 0;
    text-align: left;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu a {
    position: relative;
    transition: color 0.5 ease;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu a:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    top: 0.9rem;
    height: 4rem;
    background-color: #3ab24a;
    transition: width 0.5s ease;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu a:hover {
    color: #fff;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu a:hover .icon {
    position: relative;
    color: #fff;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu a:hover:before {
    width: 31.5rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu--obeauty .filter-menu__text {
    top: -0.5rem;
    height: 6.4rem;
    line-height: 6.4rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu--obeauty a:before {
    top: -0.4rem;
    height: 6.4rem;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu--obeauty li:first-child .filter-menu__text {
    left: 6rem;
    top: 0;
  }
  .product-pages-header__left--no-filter .product-pages-header__left__filter-menu--obeauty li:first-child a:before {
    top: 0;
  }
  .product-list-page .header {
    border-bottom: 1px solid #f6f6f6;
    height: 8.3rem;
  }
  .product-list-page .product-list-wrapper {
    padding-top: 13rem;
    margin-left: 28rem;
  }
  .product-list-page .product:nth-child(3n+1) {
    clear: left;
  }
  .product-list-page .product__thumb {
    margin-bottom: 2rem;
  }
  .product-list-page .product-list-filter {
    top: 6.6rem;
  }
  .product-list-page .product-list-filter__footer {
    bottom: 6.6rem;
  }
  .product-list-page .product-list-filter__child--colors .product-list-filter__child__choice:hover {
    opacity: 1;
  }
  .product-list-page .product-list-filter__child__choice:hover {
    background-color: #3ab24a;
  }
  .product-detail-page {
    margin-top: 13rem;
  }
  .product-detail-page .product-slider .dot-link a:hover {
    background-color: #000;
  }
  .product-detail-page .list-product-detail {
    max-width: 117rem;
  }
  .product-detail-page .list-product-detail__items .products-items {
    padding-top: 8rem;
  }
  .product-detail-page .detail-product {
    max-width: 117rem;
  }
  .product-detail-page .navbar-product {
    max-width: 114rem;
  }
  .product-detail-page .navbar-product ul {
    text-align: right;
    float: right;
    width: 100%;
    margin-bottom: 0;
  }
  .product-detail-page .navbar-product li {
    display: inline-block;
    font-size: 14px;
  }
  .product-detail-page .navbar-product li a {
    color: #fff;
  }
  .product-detail-page .navbar-product li i {
    color: #fff;
  }
  .product-detail-page .navbar-product .top {
    display: block;
    padding-top: 2rem;
  }
  .product-detail-page .navbar-product .top li {
    border-left: 1px solid #c3c3c4;
  }
  .product-detail-page .navbar-product .top li:first-child {
    border-left: 0;
  }
  .product-detail-page .navbar-product .top li:last-child a {
    padding-right: 0;
  }
  .product-detail-page .navbar-product .top a {
    transition: color 0.3s linear;
    padding: 0 4rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .product-detail-page .navbar-product .top a:hover {
    color: #9b9b9b;
  }
  .product-detail-page .navbar-product .top a i {
    font-size: 1.6rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
  }
  .product-detail-page .detail-right .icon-connect a:hover {
    opacity: 0.6;
  }
  .one-news-item {
    margin-bottom: 0;
    width: 100%;
  }
  .one-news-item:first-child {
    padding-top: 8rem;
  }
  .one-news-item__content {
    margin-bottom: 0;
  }
  .list-news {
    margin-top: 0;
    padding-top: 0;
    border-right: 1px solid #ebebeb;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-right: 4rem;
  }
  .sidebar-right {
    padding-left: 4rem;
    padding-top: 8rem;
  }
  .fanpage img {
    width: 100%;
  }
  .icon-news {
    padding-right: 2.5rem;
    display: inline-block;
  }
  .wrapper-content {
    max-width: 117rem;
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .connect__send-mail {
    padding: 0 30px;
  }
  .connect__address, .connect__form {
    padding-left: 15px;
    padding-right: 15px;
  }
  .recruit .list-news {
    padding-left: 1.5rem;
  }
  .recruit-content {
    padding-top: 8rem;
  }
  .faq-content {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .sidebar-right {
    display: block;
  }
  .about-us-slider {
    width: 100%;
    height: 50vh;
    min-height: 40rem;
  }
  .about-us-slider li {
    top: 55%;
    width: 50%;
  }
  .about-us-slider li.about-us-slider__current {
    width: 50%;
  }
  .news-detail .news-left {
    text-align: right;
    padding-right: 10rem;
    padding-bottom: 0;
  }
  .news-detail .news-right {
    text-align: left;
    border-left: 1px solid #ddd;
    border-top: none;
    padding-left: 10rem;
    padding-top: 0;
  }
  .news-detail .news-right p {
    padding-top: 0;
  }
  .news-detail .title-news {
    text-align: center;
  }
  /**
 * Main carousel
 */
  .carousel--banner__scroll-arrow {
    bottom: 3rem;
  }
  .tabs-item {
    top: 50%;
    transform: translateY(-70%);
  }
  .map-distribute {
    text-align: right;
  }
  .map-distribute__container {
    position: relative;
    display: inline-block;
    max-width: 85.6rem;
    width: 100%;
    padding-top: 45%;
  }
  .map-distribute__green-overlay-container {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .map-distribute__green-overlay-container img {
    position: absolute;
  }
  .map-distribute__green-overlay--vn {
    z-index: 2;
    top: 51%;
    left: 74.8%;
    width: 1.8rem;
  }
  .map-distribute__green-overlay--usa {
    top: 15%;
    left: 0;
    width: 24rem;
  }
  .map-distribute__green-overlay--cn {
    top: 30.8%;
    left: 67.5%;
    width: 14.2rem;
  }
  .map-distribute__marker-container {
    z-index: 2;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;
  }
  .map-distribute__marker {
    position: absolute;
    cursor: pointer;
    pointer-events: visible;
  }
  .map-distribute__anotation {
    margin-top: 0;
    margin-left: -1rem;
    font-size: 1.3rem;
    text-align: left;
    position: absolute;
    top: 66%;
    transform: translateY(-30%);
    line-height: 2.8rem;
  }
  .map-distribute__anotation__icon {
    margin-right: 1.8rem;
  }
  .flag-popover {
    background-color: #fff;
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;
    height: 4rem;
    width: 13rem;
    padding: 0.5rem;
    transform: translate(-5.5rem, -5rem);
    border-radius: 0.2rem;
    box-shadow: 0.3rem 0.3rem 0.2rem rgba(0, 0, 0, 0.2);
  }
  .flag-popover img {
    width: 100%;
    height: 100%;
  }
  .flag-popover__arrow {
    position: absolute;
    bottom: -7px;
    width: 0;
    height: 0;
    right: 6rem;
    border-left: 0 solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #fff;
  }
  .map {
    position: absolute;
    top: 0;
  }
  .map--desktop {
    display: block;
  }
  .map--mobile {
    display: none;
  }
  .intro-page .bg-baner {
    opacity: 1;
  }
  .intro-page .banner-item__content {
    left: 20%;
  }
  .intro-page .banner-item__content .visited {
    margin-right: 2rem;
  }
  .intro-page .banner-item__content .main-heading {
    opacity: 1;
    top: 0;
  }
  .guideAdmin-page .main-content {
    padding-left: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .home-item--1 .thumb, .home-item--1 .desc {
    height: 42rem;
    float: right;
    padding-top: 8%;
  }
  .cover-block-video {
    padding-bottom: 0;
  }
  .cover-block-video__checkimg-respon {
    height: auto;
  }
  .map-distribute__container {
    padding-top: 37%;
  }
  .map-distribute__anotation {
    margin-left: 3rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
