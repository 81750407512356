.bg-baner {
	opacity: 0;
	transition: opacity 1.5s ease;

	.banner-item {

		.content-container {
			position: relative;
			height: 100vh;
		}

		&__content {
			position: absolute;
			width: 65rem;
			left: inherit;
			bottom: 0;
			top: 50%;
			transform: translateY(-50%);

			.main-heading {
				position: relative;
				display: block;
		    	width:100%;
		    	padding: 0;
				top: 10rem;
				opacity: 0;
				transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
			}

			.content-arrow {
				position: relative;
				display: block;
				padding: 0;
				top: 10rem;
				opacity: 0;
				transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);

				p {
					width: 85%;
				}
			}

		}

	}

}

.main-banner-height-45 {
	position: relative;
	height: 45rem;

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: ' ';
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
	}
}

.arrow-des {
	// left: 0;
	margin: 0;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.5);

	.bx-prev, .bx-next {
		padding: 1.5rem;
		display: inline-block;
		color: $white;
	}

}

.arrow-prev {
	float: left;
	// margin-left: 1.5rem;
}

.arrow-next {
	float: right;
	// margin-right: 1.5rem;
}

.arrow-tab {
	display: block;
	margin: auto;
}
