/**
 * Main carousel
 */


.carousel--banner {

	&__scroll-arrow {
		bottom: 3rem;
	}

}

