.about-us-slider {
	width: 100%;
	height: 50vh;
	min-height: 40rem;

	li {
		top: 55%;
		width: 50%;

		&.about-us-slider__current {
			width: 50%;
		}
	}

}
