.news-detail {

	.news-left {
		text-align: right;
		padding-right: 10rem;
		padding-bottom: 0;
	}

	.news-right {
		text-align: left;
		border-left: 1px solid $grey-1;
		border-top: none;
		padding-left: 10rem;
		padding-top: 0;

		p {
			padding-top: 0;
		}

	}

	.title-news {
		text-align: center;

	}
}