//trademark-export page

.trademark-omi-page, .trademark-obeauty-page, .trademark-nghia-page, .trademark-export-page {

	.introduce {
		padding: 6rem 1.5rem 14rem;
		margin:  0 auto;

		.thumb {
			// padding-top: 10rem;
			// padding-left: 10%;

			img {
				width: 80%;
				// width: 50%;
				height: auto;
				text-align: right;
			}
		}

		.introduce-text {
			padding-right: 0;
			padding-top: 3rem;
		}
	}

	.products {
		padding-left: 0;
		padding-right: 0;
	}

	.tabs-item {

	    &--1 {

			.desc {
				margin: 0 auto;
				padding: 2rem 0 0;

			}
		}



		// .thumb {

		// 	img {
		// 		max-width: 25rem;
		// 	}
		// }
	}

	.tabs__content {
		padding-left: 0;
		padding-right: 0;
		min-height: auto;
		height: auto;
	}

	.tabs--trademark {

		.export-desc {
			overflow:hidden;
			display: table;
			padding-left: 0;
			padding-right: 0;

			p {
				text-align: left;
				padding-left: 0;
				display: table-cell;
				vertical-align: middle;
				float: none;
			}

			.thumb {
				padding-bottom: 0;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	.export-item {

		&--1 {

			display: table;

			.desc {
				padding: 10rem 8.5rem;
			}
		}

		.desc, .thumb {
			display: table-cell;
			float: none;
			vertical-align: top;
		}

		.thumb {

			&.sharpen-imports {
				background-size: cover;
				background-position:center center;
				width:50%;
				padding-bottom: 37%;
			}
		}
	}

	.carousel--banner {
		height: 100%;
	}

	.sm {
		display:inline-block;
	}

	.tl {
		display:none;
	}

	.dk {
		display:inline-block;
	}
}
