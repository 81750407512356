// Block

.wrapper-content {
	max-width: 117rem;
	padding-right: 0;
	padding-left: 0;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}

.connect {
	&__send-mail {
		padding: 0 $grid-gutter-width;

	}

	&__address, &__form {
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;
	}
}


