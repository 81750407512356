/**
 * Styles for desktop and larger-than-desktop
 */
@import 'site/variables';
@import 'site/mixins';
@import 'site/grid-framework';
@import 'site/responsive-utilities';
@import 'site/component-animations';

@media (min-width: $screen-md-min) {
	// Medium grid
	//
	// Columns, offsets, pushes, and pulls for the desktop device range.
	@include make-grid(md);
	@import 'desktop/layout';
	@import 'desktop/header';
	@import 'desktop/home';
	@import 'desktop/contact';
	@import 'desktop/banner';
	@import 'desktop/trademark-export';
	@import 'desktop/trademark-KN';
	@import 'desktop/trademark-obeauty';
	@import 'desktop/trademark-OMI';
	@import 'desktop/about-us';
	@import 'desktop/store';
	@import 'desktop/footer';
	@import 'desktop/private';
	@import 'desktop/product-landing';
	@import 'desktop/product-list';
	@import 'desktop/product-detail';
	@import 'desktop/news';
	@import 'desktop/block';
	@import 'desktop/recruit';
	@import 'desktop/faq';
	@import 'desktop/about-us-slider';
	@import 'desktop/news-detail';
	@import 'desktop/carousel';
	@import 'desktop/tabs';
	@import 'desktop/map';
	@import 'desktop/intro';
	@import 'desktop/guideadmin';
}


@media (min-width: $screen-lg-min) {
	// Large grid
	//
	// Columns, offsets, pushes, and pulls for the large desktop device range.
	@include make-grid(lg);
	@import 'desktop/home-large';
	@import 'desktop/map-large';
	// @import 'desktop/layout-large';
}
