//about-us page
.about-page {

	.carousel--banner {
		height: 100%; /* full view port */
	}


	.main-banner {
		height: 100vh;
		position: relative;

		&__container {
			max-width: 117rem;
			margin: 0 auto;
		}

		&__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-weight: 300;
			color: $white;
			width: 55%;
			padding-left: 10rem;

			&__header {
				font-size: 4.4rem;
			}
		}
	}
	.content-width {
		padding: 0 15px;
	}

	.about-item {

		&--1 {
			width: 100%;
			position: relative;
			overflow: hidden;
			.sharpen-imports {
				right: 0;
				top: 10rem;
				max-width: 117rem;
			}

			.desc {
				padding-right: 10rem;
				// text-align: justify;
			}

		}

		&--2 {
			position: relative;
			width: 100%;
			padding-top: 2rem;
			overflow: hidden;

			.sharpen-imports {
				left: 0;
				top: 10rem;
			}

			.desc {
				float: right;
				padding-left: 2rem;
				// text-align: justify;
			}

			// .js-position-show-left {
			// 	transition: all 5s cubic-bezier(0.19, 1, 0.22, 1)!important;
			// }

			// .js-position-show-right {
			// 	transition: all 5s cubic-bezier(0.19, 1, 0.22, 1)!important;
			// }
		}

		.desc {
			color: $grey-10;
			padding-bottom: 0;
			padding-top: 11.4rem;
		}

		.thumb {

			&.sharpen-imports {
				position: absolute;
				width: 45%;
				padding-bottom: 45%;
				margin-left: 0;
				margin-right: 0;

			}

			&.image-desc {
				padding: 10% 0;
				height: auto;
				width: 50%;

				img {
					width:100%;
					height:auto;
				}
			}
		}
	}

	.video-information {
		margin: 0 auto;

		&__video-left {
			float: left;
			margin-right: 2rem;
			width: 23%;
			// overflow: hidden;

			img {
				width: 100%;
			}
		}

		&__video-main {
			width: 50%;
			float: left;
			margin-left: 0.7rem;
			img {
				width: 100%;
				height: auto;
			}

		}

		&__video-right {
			float: right;
			width: 23%;
			// overflow: hidden;
			img {
				width: 100%;
			}
		}

	}

	.content-reward {
		padding: 0;
		// .sub-heading {
		// 	font-weight: 400;
		// }

		.certify {
			padding-left: 5rem;

			img {
				// width: 100%;
			}
		}

		.reward-detail {

			p {
				color: $grey-10;
				line-height: 2;
			}
		}

		.reward-list {
			margin-top: 8rem;
			margin-bottom: 8rem;
			max-width: 117rem;

			.reward {
				display: block;

				&__item {
					display: inline-block;
					width: 20%;
					img {
						width: 95%;
					}
				}

				.reward-img {
					padding-bottom: 22%;
					width:15%;
				}
			}
			.reward__item {
				// padding-top: 5rem;
				// height: 35rem;
			}

			.img-black {
				padding: 0;
				margin: 0 -0.5rem;

				.reward__item {
					width: 20%;
					padding: 0 0.5rem;
					box-sizing: border-box;


					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.tabs--trademark {

		.export-desc {
			overflow:hidden;
			margin: 0 auto;
			padding: 5% 10%;

			img {
				width: 50%;
				height: 50%;
				margin-bottom: 0;
				padding-bottom: 0;
				padding-right: 10%;
			}

			p {
				text-align: left;
				padding-left: 0;
				padding-bottom: 0;
			}
		}

		.products {
			overflow: hidden;
			padding: 0 10%;
		}
	}

	.p-product {
		padding-bottom: 0;
	}

	.tabs__content {
		padding-left: 0;
		padding-right: 0;
	}

	.tabs-item {

		&--1 {

			.desc {
				margin: 0 auto;
				padding: 2rem 1rem;
			}

		}
	}

	.reward-dk {
		padding: 5% 10%;

		img {
			margin-right: 3%;
			margin-bottom: 3%;
			width: 100%;
			max-width: 15rem;
			height: auto;
		}
	}

	.sm {
		display: none;
	}

	.tl {
		display: none;
	}

	.dk {
		display: inline-block;
	}
}
