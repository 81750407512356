.map-distribute {

	&__container {
		padding-top: 37%;
	}

	&__anotation {
		margin-left: 3rem;
		top: 50%;
		transform: translateY(-50%);
	}
}
