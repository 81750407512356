.intro-page {

	.bg-baner {
		opacity: 1;
	}


	.banner-item__content {

		left: 20%;

		.visited {
			margin-right: 2rem;
		}

		.main-heading {
			opacity: 1;
			top: 0;
		}


	}
}