.recruit {

	.one-news-item__content__imgdesc {

		//margin-top: 4rem;
	}

	.list-news {

		padding-left: 1.5rem;
	}

	// .banner-item__content {

	// 	width: 100%;
	// }
}

.recruit-content {
	padding-top: 8rem;
}
