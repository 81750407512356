.home-item {
	&--1 {
		display: block;
		position: relative;
		height: 100vh;
		min-height: 60rem;
		padding: 0;

		.thumb, .desc {
			vertical-align: middle;
			padding-top: 0%;
		}

		.sub-heading {
			font-size: 4.4rem;
		}

		&__content-container {
			height: 100vh;
			min-height: 60rem;
			position: relative;
			max-width: 117rem;
			margin: 0 auto;
		}

		&__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 45%;
			right: 1.5rem;
			opacity: 1;

			transition: all ease 0.4s;
			animation-direction: alternate;

			&.playing {
				transform: translate(150%, -50%);
				opacity: 0;
				transition: all ease .4s;
			}
		}
	}

	&--2 {
		height: 100vh;
		min-height: 60rem;

		&__content {
			line-height: 2;
			color: $white;
		}
	}
}


.cover-block-video {
	background-size: cover;
	background-position: right;
	position: absolute;
	width: 45%;
	height: 100vh;
	min-height: 60rem;
	top: 0;
	left: 0;

	transition: all .5s ease;
	animation-direction: alternate;

	&__checkimg-respon {
		height: auto;
	}


	&__overlay {
		width: 100%;
		height: 100%;
		position: relative;
		background: rgba(0, 0, 0, 0.5);
	}

	&__play-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.playing {
		width: 100%;
		transition: all ease .4s;
	}

}

.behind-cover-block-video {
	position: absolute;
	width: 45%;
	height: 100vh;
	min-height: 60rem;
	margin-top: 0;
	background-size: cover;
	display: none;
	background-position: right;
	top: 0;
	left: 0;

	&.playing {
		width: 100%;
		transition: all ease .4s;
		display: block;
	}
}


.bg-multi-kem {
	position: relative;
	width: 100%;

	.home-item--2__content {
		position: absolute;
		top: 50%;
		left: inherit;
		transform: translateY(-50%);
		width: 47%;

		p {
			font-weight: 300;

			&.bold-text {
				font-weight: 600;
				text-align: left;
			}
		}
	}
}

.distribute {

	&__margin {
		margin-left: auto;
		margin-right: auto;
	}

}

.list-logo-branch {
	margin: 6rem 0 10rem;

	ul {
		display: flex;
		justify-content: space-between;
		padding: 0 5rem;
		align-items: flex-end;

		li {
			width: auto;
			margin: 0;
			height: auto;
			position: relative;

			a {
				text-align: center;

			}

			&.list-logo-branch__separator {
				height: 6rem;
				width: 1px;
				background-color: $grey-1;
			}

			// &:after {
			// 	position: absolute;
			// 	content:"";
			// 	height: 100%;
			// 	width: 2px;
			// 	background: #000;
			// }

		}

	}
}

.footer__left {
	text-align: left;
}

.footer__right {
	text-align: right;
}

.tabs {
	&__content {
		height: 100vh;
		min-height: 60rem;
	}
}


.homepage {

	.history-us {
		position: relative;
		height: 100%;

		&__period {
			position: absolute;
			left: 1.5rem;
			margin-top: 7%;
			top: 0;
			padding-top: 15rem;
		}

		&__slide-index-text {
			display: none;
		}

		&__slide-index {
			position: relative;
			display: inline-block;
			width: 2rem;
			margin-right: .8rem;

			&::before {
				position: absolute;
				bottom: 0;
				left: 0;
				content: ' ';
				display: block;
				width: 1rem;
				height: .3rem;
				background-color: $grey-1;
				transition: all .3s ease-out;
			}

		}

		li {
			margin-bottom: 0;
			height: 100%;
		}

		.active {

			&::before {
				width: 2rem;
				margin-left: -.5rem;
				background-color: $black;
			}

			~ .history-us__slide-index-text {
				display: inline-block;
				color: $grey-13;
				vertical-align: top;
				padding-left: 1rem;
				line-height: 0.3;
				height: 1.7rem;
				border-bottom: 1px solid $grey-1;
			}

		}


		&__header {
			display: flex;
			justify-content: space-between;

			.sub-heading {
				display: inline-block;
			}
		}

		&__header2 {
			padding: 7rem 10rem 0;
			display: flex;
			position: relative;

			.sub-heading {
				display: inline-block;
			}

			.history-us__btn-group {
				position: absolute;
				right: 1.5rem;
			}
		}

		&__btn-group {
			display: inline-block;
		}


		&__btn {
			display: inline-block;
			margin-left: 2rem;
			height: 5rem;

			.bx-prev {
				position: relative;
				top: 1.4rem;
				padding: 1.2rem 1.5rem 1rem;
				border: 2px solid $black;
			}

			.bx-next {
				position: relative;
				top: 1.4rem;
				padding: 1.2rem 1.5rem 1rem;
				border: 2px solid $black;
			}
		}

		&__content {
			transition: all .3s ease-out;
			margin: 0 auto;
			padding-top: 4%;
			max-width: 117rem;
			padding-left: 10rem;
			padding-right: 1.5rem;
			min-height: 45rem;
		}

		&__text {
			display: inline-block;
			width: 50%;
			padding-right: 6rem;
			padding-left: 0;
		}

		&__image {
			margin-top: 4rem;
			width: 50%;
			padding-left: 6rem;
			display: inline-block;
			vertical-align: top;
			padding-bottom: 0;
		}

	}
}

@media screen and (max-width: 1330px) {
	.homepage {

		.history-us {

			&__content {
				padding-left: 22rem;
			}

			&__text {
				padding-right: 4rem;
			}

			&__image {
				padding-left: 4rem;
			}
		}
	}
}
