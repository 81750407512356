// Store
.map-wrapper {
	display: block;
}
.block-list-store {

	.store-address {

		&.active {

			.title-info {

				&:after {
					content: '\ea07';
				}
			}
		}
	}


}