.product-list-page {

	.header {
		border-bottom: 1px solid $grey-8;
		height: 8.3rem;
	}

	.product-list-wrapper {
		padding-top: 13rem;
		margin-left: 28rem;
	}

	.product {
		&:nth-child(3n+1) {
			clear: left;
		}

		&__thumb {
			margin-bottom: 2rem;
		}
	}

	.product-list-filter {
		top: 6.6rem;

		&__footer {
			bottom: 6.6rem;
		}
	}

	.product-list-filter__child--colors {
		.product-list-filter__child__choice {
			&:hover {
				opacity: 1;
			}
		}
	}

	.product-list-filter__child__choice {
		&:hover {
			background-color: $green-1;
		}
	}

}
