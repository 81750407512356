.contact-page {
	margin-top: 8rem;

	.main {
		padding-top: 15rem;
	}

	.title {
		font-weight: 300;
		font-size: 3.6rem;
		width: 50%;
		margin: 0 auto 8rem;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}

	.contact-form {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin: 0;

		textarea, input {

			&::placeholder {
				color: $black;
			}
		}

		textarea {
			height: 14.5rem;
		}
	}

	.block-list-store {
		margin-top: 1rem;
	}

	.connect {
		border-top: 1px solid $grey-1;
		background-color: $white;

		padding-top: 0;
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		.main-title--1 {
			text-transform: none;
			font-size: 22px;
		}

		p {
			line-height: 2;
			font-size: 1.6rem;
		}

		&__address {
			margin-top: 0;

			.main-title--1 {
				margin-bottom: 1.2rem;
			}

			p {
				color: $black;
			}

			span {
				width: 7rem;
			}
		}

		.follow__social a {
			margin-left: 3rem;

			&:first-child {
				margin-left: 0;
			}
		}


	}
}

.form-contact-area {
    margin-bottom: 0;
}

.third-party-logos {
	text-align: left;
}