.one-news-item {
	margin-bottom: 0;
	width: 100%;

	&:first-child {
		padding-top: 8rem;
	}

	&__content {
		margin-bottom: 0;
	}
}

.list-news {
	margin-top: 0;
	padding-top: 0;
	border-right: 1px solid $grey19;
	padding-bottom: 0;
	margin-bottom: 0;
	padding-right: 4rem;

}

.sidebar-right {
	padding-left: 4rem;
	padding-top: 8rem;
}
.fanpage {

	img {
		width: 100%;
	}
}
.icon-news {
	padding-right: 2.5rem;
	display: inline-block;
}
