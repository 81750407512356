// tabs

.tabs-item {
	top: 50%;
	transform: translateY(-70%);
}















