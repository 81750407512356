// Desktop layout


.set-sub-margin-lg {
	margin-left: -15px;
	margin-right: -15px;
}

.section-cover {
	text-align: center;
	height: 100vh;
	min-height: 60rem;

	&__content {
		position: absolute;
		width: 55%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-top: 0;

		&__title {
			margin-bottom: 4rem;
		}
	}
}

.sidebar-right {
	float: right;
}

.bg-cover-other-pr {
	padding-top: 0;
	position: relative;
	padding-bottom: 0;
	height: 100%;

	// &:before {
	// 	content: ' ';
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: rgba(44, 46, 55, 0.8);
	// }

	&--banner-top-static {
		padding-bottom: 0%;
	}
}

.content-container {
	max-width: 117rem;
	padding: 0 10rem;
	margin: 0 auto;
	position: relative;
	height: 100%;

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.content-width {
	max-width: 117rem;
	padding: 0 15px;
}

/* ========Setup Animate Scroll ===========*/


.js-title-animate-hide {
	transform: translateY(100%) !important;
	opacity: 0 !important;
	top: 100%;
}

.js-content-animate-hide {
	transform: translateY(100%) !important;
	opacity: 0 !important;
	top: 100%;
}

.js-title-animate-show {
	top: 50%;
	transform: translateY(-70%) !important;
	opacity: 1 !important;
	transition: all ease 0.3s;
}

.js-content-animate-show {
	top: 10%;
	transform: translateY(-20%) !important;
	opacity: 1 !important;
	transition: all ease 2s;
}

.js-position-hide-left {
	transform: translateX(-100%);
	opacity: 0;
}

.js-position-hide-right {
	transform: translateX(100%);
	opacity: 0;
}

.js-position-hide-fade {
	opacity: 0;
}

.js-position-hide-top {
	transform: translateY(-100%);
	opacity: 0;
}

.js-position-show-left {
	transform: translateX(0%);
	opacity: 1;
	transition: all ease 2s !important;
}

.js-position-show-right {
	transform: translateX(0%);
	opacity: 1;
	transition: all ease 2s !important;
}

.js-position-show-fade {
	opacity: 1;
	transition: all ease 0.5s;
}

.js-position-show-top {
	transform: translateY(0%);
	opacity: 1;
	transition: all ease 0.1s;
}

.js-position-hide-below {
	transform: translateY(50%);
	opacity: 0;
}

.js-position-show-below {
	transform: translateY(0%);
	opacity: 1;
	transition: transform 3s cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.js-position-title-hide-below, .js-position-content-animate-hide {
	transform: translateY(50%);
	opacity: 0;
}

.js-position-title-show-below {
	transform: translateY(0%);
	opacity: 1;
	transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1) !important;

}

.js-position-content-animate-show {
	transform: translateY(0%);
	opacity: 1;
	transition: all 3s cubic-bezier(0.19, 1, 0.22, 1);
}

.js-cover-set-dib {
	display: inline-block;
	margin-bottom: -0.7rem;
}

.custom-position-animate {
	.js-title-animate-show {
		transform: translateY(-30%) !important;
	}
}

.js-change-logo {
	transition: all ease 0.4s;
}

.js-animate-banner-content-stat-1 {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-10%);
	transition: all ease 0.3s;
}

.js-animate-banner-content-stat-2 {
	opacity: 1;
	visibility: visible;
	transform: translateY(-50%);
	transition: all ease 0.3s;
}

/* ======== end Setup Animate Scroll ===== */
