.map-distribute {
	text-align: right;

	&__container {
		position: relative;
		display: inline-block;
		max-width: 85.6rem;
		width: 100%;
		padding-top: 45%;
	}

	&__green-overlay-container {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;

		img {
			position: absolute;
		}
	}

	&__green-overlay {

		&--vn {
			z-index: 2;
			top: 51%;
			left: 74.8%;
			width: 1.8rem;
		}

		&--usa {
			top: 15%;
			left: 0;
			width: 24rem;
		}

		&--cn {
			top: 30.8%;
			left: 67.5%;
			width: 14.2rem;
		}
	}

	&__marker-container {
		z-index: 2;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		pointer-events: none;
	}

	&__marker {
		position: absolute;
		cursor: pointer;
		pointer-events: visible;
	}

	&__anotation {
		margin-top: 0;
		margin-left: -1rem;
		font-size: 1.3rem;
		text-align: left;
		position: absolute;
		top: 66%;
		transform: translateY(-30%);
		line-height: 2.8rem;

		&__icon {
			margin-right: 1.8rem;
		}
	}
}

.flag-popover {
	background-color: $white;
	position: absolute;
	transition: opacity 0.5s ease;
	opacity: 0;
	height: 4rem;
	width: 13rem;
	padding: 0.5rem;
	transform: translate(-5.5rem, -5rem);
	border-radius: 0.2rem;
	box-shadow: 0.3rem 0.3rem 0.2rem rgba(0, 0, 0, 0.2);

	img {
		width: 100%;
		height: 100%;
	}

	&__arrow {
		position: absolute;
		bottom: -7px;
		width: 0;
		height: 0;
		right: 6rem;
		border-left: 0 solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid $white;
	}
}

.map {
	position: absolute;
	top: 0;

	&--desktop {
		display: block;
	}

	&--mobile {
		display: none;
	}
}
